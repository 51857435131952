import { render, staticRenderFns } from "./EmailPropspectinInfoDropdown.vue?vue&type=template&id=2881eaff&"
import script from "./EmailPropspectinInfoDropdown.vue?vue&type=script&lang=ts&"
export * from "./EmailPropspectinInfoDropdown.vue?vue&type=script&lang=ts&"
import style0 from "./EmailPropspectinInfoDropdown.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports