import { render, staticRenderFns } from "./CompaniesKanban.vue?vue&type=template&id=4e718b76&"
import script from "./CompaniesKanban.vue?vue&type=script&lang=ts&"
export * from "./CompaniesKanban.vue?vue&type=script&lang=ts&"
import style0 from "./CompaniesKanban.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports